"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLastDayOfYearAsLocalDateString = exports.getFirstDayOfYearAsLocalDateString = exports.getLastDayOfMonthAsLocalDateString = exports.getFirstDayOfMonthAsLocalDateString = exports.getTodayInOneYearAsLocalDateString = exports.getNowAsLocalDateString = exports.toLocalDateString = exports.toLocalISOString = exports.getDateDifferenceInDays = exports.formatTime = exports.formatDateMonthPlusYear = exports.formatDateShort = exports.formatDateFullNoSeconds = exports.formatDateFull = exports.formatDateYearMonthDay = exports.toDateString = void 0;
var toDateString = function (date) {
    return date != null ? date.toISOString().split('T')[0] : null;
};
exports.toDateString = toDateString;
var dateTimeFormatYearMonthDay = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
});
var formatDateYearMonthDay = function (date) { return !date
    ? null
    : dateTimeFormatYearMonthDay.format(new Date(date)); };
exports.formatDateYearMonthDay = formatDateYearMonthDay;
var dateTimeFormatFull = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
});
var formatDateFull = function (date) { return !date
    ? null
    : dateTimeFormatFull.format(new Date(date)).split(', 00:00:00').join('').split(', ').join(' '); };
exports.formatDateFull = formatDateFull;
var dateTimeFormatFullNoSeconds = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
});
var formatDateFullNoSeconds = function (date) { return !date
    ? null
    : dateTimeFormatFullNoSeconds.format(new Date(date)).split(', 00:00:00').join('').split(', ').join(' '); };
exports.formatDateFullNoSeconds = formatDateFullNoSeconds;
var dateTimeFormatShort = new Intl.DateTimeFormat('de-DE', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
});
var formatDateShort = function (date) { return !date
    ? null
    : dateTimeFormatShort.format(new Date(date)); };
exports.formatDateShort = formatDateShort;
var dateTimeFormatMonthPlusYear = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: 'short'
});
var formatDateMonthPlusYear = function (date) { return !date
    ? null
    : dateTimeFormatMonthPlusYear.format(new Date(date)); };
exports.formatDateMonthPlusYear = formatDateMonthPlusYear;
var timeFormat = new Intl.DateTimeFormat('de-DE', {
    hour: 'numeric',
    minute: 'numeric'
});
var formatTime = function (date) { return !date
    ? null
    : timeFormat.format(new Date(date)); };
exports.formatTime = formatTime;
var getDateDifferenceInDays = function (fromDate, toDate) { return fromDate && toDate
    ? (new Date(toDate).getTime() - new Date(fromDate).getTime()) / 24 / 60 / 60 / 1000 + 1
    : null; };
exports.getDateDifferenceInDays = getDateDifferenceInDays;
var toLocalISOString = function (d) {
    var pad = function (number) { return ('' + number).padStart(2, '0'); };
    return "".concat(d.getFullYear(), "-").concat(pad(d.getMonth() + 1), "-").concat(pad(d.getDate()), "T").concat(pad(d.getHours()), ":").concat(pad(d.getMinutes()), ":").concat(pad(d.getSeconds()));
};
exports.toLocalISOString = toLocalISOString;
var toLocalDateString = function (d) { return new Date(d).toISOString().split('T')[0]; };
exports.toLocalDateString = toLocalDateString;
var getNowAsLocalDateString = function () { return (0, exports.toLocalDateString)(new Date()); };
exports.getNowAsLocalDateString = getNowAsLocalDateString;
var getTodayInOneYearAsLocalDateString = function () {
    var now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    return (0, exports.toLocalDateString)(now);
};
exports.getTodayInOneYearAsLocalDateString = getTodayInOneYearAsLocalDateString;
var getFirstDayOfMonthAsLocalDateString = function () {
    var now = new Date();
    now.setDate(1);
    return (0, exports.toLocalDateString)(now);
};
exports.getFirstDayOfMonthAsLocalDateString = getFirstDayOfMonthAsLocalDateString;
var getLastDayOfMonthAsLocalDateString = function () {
    var now = new Date();
    now.setMonth(now.getMonth() + 1);
    now.setDate(0);
    return (0, exports.toLocalDateString)(now);
};
exports.getLastDayOfMonthAsLocalDateString = getLastDayOfMonthAsLocalDateString;
var getFirstDayOfYearAsLocalDateString = function () {
    var now = new Date();
    now.setDate(1);
    now.setMonth(0);
    return (0, exports.toLocalDateString)(now);
};
exports.getFirstDayOfYearAsLocalDateString = getFirstDayOfYearAsLocalDateString;
var getLastDayOfYearAsLocalDateString = function () {
    var now = new Date();
    now.setMonth(now.getMonth() + 1);
    now.setDate(0);
    now.setMonth(12);
    return (0, exports.toLocalDateString)(now);
};
exports.getLastDayOfYearAsLocalDateString = getLastDayOfYearAsLocalDateString;
