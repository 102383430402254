"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultFormConfigEntry = void 0;
var tslib_1 = require("tslib");
var getDefaultFormConfigEntry = function (entry, localOverrides, entryName) { return (tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, base), getGlobalOverride(entry, entryName)), localOverrides)); };
exports.getDefaultFormConfigEntry = getDefaultFormConfigEntry;
var base = {
    show: true,
    width: 'single',
    height: 1
};
var getGlobalOverride = function (entry, entryName) {
    if (entryName === 'id') {
        return { show: true, disabled: !!entry.readOnly };
    }
    else if (entryName === 'shortId') {
        return { show: true, disabled: !!entry.readOnly };
    }
    else if (entryName === 'deleted') {
        return { show: false };
    }
    else if (entryName === 'discriminator') {
        return { show: false };
    }
    else if (entryName === 'firstPublishedAt') {
        return { show: false };
    }
    else if (entryName === 'hasAcceptedPublication') {
        return { show: false };
    }
    else if (entryName === 'lastPublicationStatus') {
        return { show: false };
    }
    else if (entryName === 'custom') {
        return { show: false };
    }
    else if (entryName === 'linkedContactPersons') {
        return { show: true };
    }
    else if (entryName === 'title') {
        return { width: 'full' };
    }
    else if (entryName === 'description' || entryName === 'bounds' || entryName === 'contentEmail' || entryName === 'content' || entryName.endsWith('Description') || entryName.endsWith('Template') || entryName === 'memo') {
        return { isTextarea: true, width: 'full' };
    }
    else if (entry.fkey) {
        return { show: false };
    }
    return {};
};
